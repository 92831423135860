import { useState } from "react";
import reactLogo from "./assets/react.svg";
import { BrowserRouter, useRoutes } from "react-router-dom";
import Home from "./pages/index";
import HomePage from "./pages/homepage";
import Chat from "./pages/chat";

function App() {
  const [count, setCount] = useState(0);

  const element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/homepage", element: <HomePage /> },
    { path: "/chat", element: <Chat /> },
    // {
    //   element: <AuthLayout />,
    //   children: [
    //     { path: "/", element: <Login /> },
    //     { path: "signup", element: <SignUp /> },
    //   ],
    // },
  ]);

  return <div className="App">{element}</div>;
}

export default App;

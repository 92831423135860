export const initMessageConnect = () => {
  const eventSource = new EventSource(
    "https://www.levenx.com/octopus/order/push",
  );

  eventSource.onmessage = ({ data }) => {
    const eventData = JSON.parse(data);
    console.log("eventData:", eventData);

    const { title, content } = eventData;

    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log("用户同意授权");
        var notification = new Notification(title, { body: content });

        // 随时可以显示通知
      } else if (permission === "default") {
        console.log("用户关闭授权,可以再次请求授权");
      } else {
        console.log("用户拒绝授权,不能显示通知");
      }
    });

    // handling the data from the server
  };
};

import { useEffect } from "react";
import { initMessageConnect } from "../services/message";

export default function Home() {
  useEffect(() => {
    initMessageConnect();
  }, []);

  return <div>Home</div>;
}

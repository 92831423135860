import React from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

export default function useCustomerChat() {
  const { sendJsonMessage, readyState } = useWebSocket(
    "wss://www.levenx.com/customer",
    {
      retryOnError: true,
      queryParams: { id: "1" },
      onMessage: event => {
        console.log("event:", event);
        const messageData = JSON.parse(event.data);
        if (messageData) {
          console.log("messageData");
          console.log("messageData:", messageData);
        }
      },
    },
  );

  console.log("readyState:", readyState);

  return {};
}
